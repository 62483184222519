import { connect } from "react-redux"

import {
  removeProduct,
  increaseQuantity,
  decreaseQuantity,
} from "../../../redux/cart/actions"

import CartRowView from "./cart-row-view"

export default connect(null, {
  removeProduct,
  increaseQuantity,
  decreaseQuantity,
})(CartRowView)
