import React, { MouseEvent } from "react"

import {
  Box,
  Button,
  ButtonGroup,
  Grid,
  Link,
  Typography,
  useMediaQuery,
} from "@material-ui/core"

import { makeStyles, useTheme } from "@material-ui/core/styles"
import { Add, Remove } from "@material-ui/icons"

import { Link as GatsbyLink } from "gatsby"

import { ViewProps } from "./cart-row-types"
import CentsToEuro from "../../../utils/cents-to-euro"
import Image from "../../utils/image/image-view"

const useStyles = makeStyles({
  disableButton: {
    cursor: `default`,
    pointerEvents: `none`,
  },
})

const CartRowView = ({
  product,
  title,
  slug,
  excerpt,
  images,
  quantity,
  totalPrice,
  removeProduct,
  increaseQuantity,
  decreaseQuantity,
}: ViewProps) => {
  const theme = useTheme()
  const isSmall = !useMediaQuery(theme.breakpoints.up(`md`))

  const classes = useStyles()

  const handleRemoveProductFromCart = (e: MouseEvent) => {
    removeProduct(product)
    e.preventDefault()
  }

  const handleIncreaseProductCartQuantity = () => {
    increaseQuantity(product)
  }

  const handleDecreaseProductCartQuantity = () => {
    decreaseQuantity(product)
  }

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={4} sm={3} md={2} lg={1}>
        <Link component={GatsbyLink} to={`/shop/${slug}`}>
          <Image src={images[0]} alt={title} />
        </Link>

        <Box mt={1} alignItems="center" display={{ xs: `block`, md: `none` }}>
          <Typography variant="body2" align="center">
            <Link
              href="#"
              underline="always"
              onClick={handleRemoveProductFromCart}
            >
              Verwijderen
            </Link>
          </Typography>
        </Box>
      </Grid>

      <Grid item xs={8} sm={9} md={10} lg={11}>
        <Grid container alignItems="center" spacing={isSmall ? 0 : 2}>
          <Grid item xs={12} md={6}>
            <Typography variant="h3" component="h2" gutterBottom>
              <Link component={GatsbyLink} to={`/shop/${slug}`}>
                {title}
              </Link>
            </Typography>

            <Typography variant="body2" className="mb1">
              {excerpt}
            </Typography>

            <Box display={{ xs: `none`, md: `block` }}>
              <Typography variant="body2">
                <Link
                  href="#"
                  underline="always"
                  onClick={handleRemoveProductFromCart}
                >
                  Verwijderen
                </Link>
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} md={3}>
            <Typography variant="body2" className="bold" gutterBottom>
              Aantal
            </Typography>

            <ButtonGroup size="small">
              <Button
                disabled={quantity === 1}
                onClick={handleDecreaseProductCartQuantity}
              >
                <Remove />
              </Button>

              <Button className={classes.disableButton}>{quantity}</Button>

              <Button onClick={handleIncreaseProductCartQuantity}>
                <Add />
              </Button>
            </ButtonGroup>
          </Grid>

          <Grid item xs={12} md={3}>
            <Typography className="bold floatRight">
              {CentsToEuro(totalPrice)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default CartRowView
