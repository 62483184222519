import React from "react"

import {
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core"

import { ViewProps } from "./change-country-dialog-types"
import { Country } from "../../../api/sync"
import { countryData } from "../../../data/order"

const ChangeCountryDialogView = ({
  open,
  setOpen,
  setShippingCountry,
}: ViewProps) => {
  const handleClose = () => {
    setOpen(false)
  }

  const handleClick = (country: Country) => {
    setShippingCountry(country)
    setOpen(false)
  }

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Land wijzigen</DialogTitle>
      <List disablePadding={true}>
        {(Object.values(Country) as Country[]).map(country => (
          <ListItem button onClick={() => handleClick(country)} key={country}>
            <ListItemText primary={countryData[country].label} />
          </ListItem>
        ))}
      </List>
    </Dialog>
  )
}

export default ChangeCountryDialogView
