import React, { MouseEvent } from "react"

import { Button, Divider, Grid, Link, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

import pick from "lodash/pick"
import { Link as GatsbyLink } from "gatsby"
import clsx from "clsx"

import { ViewProps } from "./cart-types"
import CentsToEuro from "../../utils/cents-to-euro"
import CartRow from "./cart-row"
import ChangeCountryDialog from "./change-country-dialog"

const useStyles = makeStyles(theme => ({
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },

  success: {
    color: theme.palette.success.main,
  },
}))

const CartView = ({
  products,
  totalPrice,
  shippingCosts,
  shippingCountry,
  shippingCostsThreshold,
}: ViewProps) => {
  if (products.length === 0) {
    return (
      <Typography>Er zitten nog geen producten in jouw winkelwagen.</Typography>
    )
  }

  const classes = useStyles()

  const [changeCountryDialogOpen, setChangeCountryDialogOpen] = React.useState(
    false
  )

  const handleClickOpenChangeCountryDialog = (e: MouseEvent) => {
    setChangeCountryDialogOpen(true)
    e.preventDefault()
  }

  return (
    <>
      <Divider className={classes.divider} />

      {products.map(product => (
        <>
          <CartRow
            key={product.id}
            product={product.id}
            {...pick(product, [
              `title`,
              `slug`,
              `excerpt`,
              `images`,
              `quantity`,
              `totalPrice`,
            ])}
          />

          <Divider className={classes.divider} />
        </>
      ))}

      <Grid container alignItems="center">
        <Grid item xs={9}>
          <Typography>Verzending {shippingCountry}</Typography>

          <Typography variant="body2">
            Gratis vanaf {CentsToEuro(shippingCostsThreshold)} -{" "}
            <Link
              href="#"
              underline="always"
              onClick={handleClickOpenChangeCountryDialog}
            >
              Land wijzigen
            </Link>
          </Typography>
        </Grid>

        <Grid item xs={3}>
          <Typography
            className={clsx(
              `bold`,
              `floatRight`,
              shippingCosts === 0 && classes.success
            )}
          >
            {shippingCosts === 0 ? `Gratis` : CentsToEuro(shippingCosts)}
          </Typography>
        </Grid>
      </Grid>

      <Divider className={classes.divider} />

      <Grid container alignItems="center">
        <Grid item xs={9}>
          <Typography className="bold">Totaal</Typography>
        </Grid>

        <Grid item xs={3}>
          <Typography className="bold floatRight">
            {CentsToEuro(totalPrice)}
          </Typography>
        </Grid>
      </Grid>

      <Divider className={classes.divider} />

      <Button
        variant="contained"
        color="secondary"
        component={GatsbyLink}
        to="/bestellen/gegevens"
      >
        Bestellen
      </Button>

      <ChangeCountryDialog
        open={changeCountryDialogOpen}
        setOpen={setChangeCountryDialogOpen}
      />
    </>
  )
}

export default CartView
