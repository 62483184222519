import React from "react"

import { Typography } from "@material-ui/core"
import { Helmet } from "react-helmet"

import MainShell from "../../components/main-shell"
import Cart from "../../components/cart"
import CheckoutStepper from "../../components/checkout-stepper"

const CartPage = () => (
  <MainShell>
    <Helmet>
      <title>Winkelwagen - Todocards.nl</title>
      <meta name="robots" content="noindex, follow" />
    </Helmet>

    <div className="mb2">
      <CheckoutStepper currentStep={1} />
    </div>

    <Typography variant="h1">Winkelwagen</Typography>
    <Cart />
  </MainShell>
)

export default CartPage
