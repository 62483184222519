import { connect } from "react-redux"

import { State } from "../../redux/types"
import { ViewProps } from "./cart-types"
import CartView from "./cart-view"
import getCartData from "../../utils/get-cart-data"
import { countryData } from "../../data/order"

const mapStateToProps = (state: State): ViewProps => {
  const { label, shippingCostsThreshold } = countryData[
    state.order.details.shippingCountry
  ]
  return {
    shippingCountry: label,
    shippingCostsThreshold,
    ...getCartData<{
      title: string
      slug: string
      excerpt: string
      images: string[]
    }>(state.cart, state.order.details.shippingCountry, [
      `title`,
      `slug`,
      `excerpt`,
      `images`,
    ]),
  }
}

export default connect(mapStateToProps)(CartView)
